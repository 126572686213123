import styled from "styled-components";

export const PressContainer = styled.div`
  width: 98%;
  margin:auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  .pressHero {
    width: 50%;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    .pressHero {
      width: 100%;
      height: 50%;
    }
  }
`;
export const HeroContent = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #f8f8f8 !important;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s linear;
  cursor: pointer;
  text-shadow: 1px 1px 3px #000000, 0px 0px 0px rgba(0,0,0,0.3);
  div {
    display: flex;
    flex-direction: column;
    height: 200px;
    
    h1 {
      width: 70%;
      margin: 0 0 0 5%;
      font-size: 6.5vw;
    }
    p {
      width: 70%;
      margin: 0 0 0 5%;
      font-size: 2vw;
    }
  }

  :hover {
    background-color: rgba(0, 0, 0, 0);
    color: black !important;
    text-shadow: 1px 1px 3px #FFFFFF, 0px 0px 0px rgba(0,0,0,0.1);
  }
  @media only screen and (max-width: 992px) and (orientation: landscape){
    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;

    div {
      width: 100%;
      text-align: center;
      height: 100vw;
      h1 {
        margin: 100px auto;
        margin-bottom: 15px;
        width: 95%;
        font-size: 13vw;
      }
      p {
        font-size: 5vw;
        margin: 0;
        width: 95%;
      }
    }
  }

  @media only screen and (max-width: 992px) and (orientation: portrait) {

    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;

    div {
      width: 100%;
      text-align: center;
      height: 75vh;
      h1 {
        margin: 100px auto;
        margin-bottom: 15px;
        width: 95%;
        font-size: 13vw;
      }
      p {
        font-size: 5vw;
        margin: 0;
        width: 95%;
      }
    }
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  /* display: ${props => (props.iframe ? "block" : "none")} */

`;

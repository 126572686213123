import React from "react";
import { getGlobal } from "reactn";
import * as Styled from "../../css/pressStyle";
import * as Style from "../../css/templateStyle";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Iframe = ({ OnEventPressBild, iframe }) => {
  let { address } = getGlobal(global.address);
  return (
    <div>
      <Styled.Iframe iframe={iframe} src={address}></Styled.Iframe>
      <Style.ArrowBack color={"black"} onClick={e => OnEventPressBild(e)}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Style.ArrowBack>
    </div>
  );
};

export default Iframe;

import styled from "styled-components";

export const ArrowBack = styled.div`
  font-size: 40px;
  color: ${props => props.color};
  background-color: ${props => (props.background ? "rgba(0,0,0,0.1);" : null)};
  height: ${props => (props.background ? "100%" : null)};
  padding: 2%;
  width: 100%;
  svg {
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 2% 0 2% 0;
  div {
    a,
    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #202020;
      :hover p {
        text-decoration: underline;
      }

      img {
        width: 2em;
      }
      p {
        margin: 5px 5px 5px 11px;
        font-family: StureplansgruppenUnderRubrik;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    margin: 5% 0;
    div {
      a,
      button {
        svg {
          font-size: 35px;
        }
        p {
          display: none;
        }
      }
    }
  }
`;

export const NewsIcon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 5px 0 20px 0;
  img {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
  }
`;

export const shortContainer = styled.div`
  width: 60%;
  margin: auto;

  h1 {
    text-align: center;
    margin: 5% 0 2% 0;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const PostContent = styled.div`
  margin: 20px 10px 30px 10px;
  h1 {
    text-align: center;
    margin: 5% 0 2% 0;
    line-height: 1.2 !important;
    width:70%;
    margin:auto;
  }
  div {
  margin:auto;
  }

  @media only screen and (max-width: 992px) {
    margin: 0 3%;
    h1 {
      font-size: 8vw !important;
      margin: 0;
      width: 100%;
    }
  }
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  div {
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    div {
      width: 100%;
    }
  }
`;

export const SmallTalkPostContainer = styled.div`
  width: 60%;
  margin: auto;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const BokaBordSingle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: center;
  margin: 15px 0;
  display: flex;
  .buttonContainer {
    width: 40%;
    max-width: 165px;
    min-width: 165px;
    height: 4vh;
    min-height: 40px;
    background-color: black;
    border: 1px solid black;
    border-radius: 15px;
    button {
      display: flex;
      align-items: center;
      height: 100%;
      background-color:transparent;

      img {
        width: 30px;
        height: auto;
      }
      div {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 0 5px;
        width: 100%;
        p {
          color: white;
          margin: 0;
          font-family: StureplansgruppenUnderRubrik;
          font-size: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    display: flex;
  }
`;
